<!--
 * @Author: wyq
 * @Date: 2021-10-04 16:32:37
 * @LastEditTime: 2021-10-12 16:19:38
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\questionInfoDialog.vue
-->
<template>
  <v-overlay light :value="dialogVisible" color="#000" opacity="0.6">
    <div class="dialog-template">
      <v-btn class="btn-close" icon color="#fff" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <question-preview drawer :questionInfo="questionInfo" @reasonChange="reasonChange"></question-preview>
    </div>
  </v-overlay>
</template>
<script>
import mixin from '@/utils/dialogMixin'
import questionPreview from '../testing/questionPreview'
export default {
  mixins: [mixin],
  components: {
    questionPreview,
  },
  props: {
    questionInfo: Object,
  },
  watch: {
    questionInfo(val) {
      console.log(val)
    },
  },
  methods: {
    reasonChange() {
      this.$emit('reasonChange')
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-close {
  position: absolute;
  top: -5vh;
  right: -1vw;
}
.dialog-template {
  width: calc(100vw - 40px);
  height: calc(100vh - 100px);
  color: #333;
}
</style>