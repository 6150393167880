<!--
 * @Author: yy
 * @Date: 2021-09-29 16:56:30
 * @LastEditTime: 2022-04-14 15:23:41
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\components\wrongQuestion\reviseMask.vue
-->
<template>
  <div>
    <!-- <v-overlay :value="show"> -->
    <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
      <div class="content">
        <div class="contentTitle">错因</div>
        <div class="tagView">
          <div
            v-for="(item,index) in tagList"
            :class="selectIndex == index?'chooseTag':''"
            :key="index"
            @click="changeTag(index)"
          >{{item.name}}</div>
        </div>
        <div class="classifyTitle">
          <span>知识点分类</span>
          <v-icon v-if="openTree" @click="openTree = !openTree">icon-kaiguankai</v-icon>
          <v-icon style="color:#d8d8d8" v-else @click="openTree = !openTree">icon-kaiguanguan</v-icon>
        </div>
        <div ref="katex" class="treeView" v-show="openTree">
          <v-treeview
            selectable
            :items="tree"
            v-model="label"
            item-text="label"
            @update:open="clickNode"
          ></v-treeview>
        </div>
        <div class="btnView">
          <div class="resetBtn" @click="onReset">重置</div>
          <div class="submitBtn" @click="onSubmit">确定</div>
        </div>
      </div>
    </v-dialog>
    <!-- </v-overlay> -->
  </div>
</template>
<script>
export default {
  props: {
    show: Boolean,
  },
  watch: {
    openTree: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.$formula(this.$refs['katex'])
          }, 100)
        }
      },
      immediate: true,
    },
  },
  data: () => ({
    openTree: false,
    tagList: [
      {
        name: '全部',
        id: null,
      },
      {
        name: '知识点未掌握',
        id: 1,
      },
      {
        name: '解题方法未掌握',
        id: 2,
      },
      {
        name: '计算错误',
        id: 3,
      },
      {
        name: '粗心错误',
        id: 4,
      },
      {
        name: '其他',
        id: 5,
      },
    ],
    selectIndex: 0,
    tree: [],
    label: [],
  }),
  mounted() {
    this.getIndustryTree()
  },
  methods: {
    //获取知识点分类树
    getIndustryTree() {
      this.$service.getTree({
        subject: this.$store.state.subject,
      }).then((res) => {
        // 节选
        this.tree = res.tree
      })
    },
    changeTag(index) {
      this.selectIndex = index
    },
    onReset() {
      this.selectIndex = 0
      this.openTree = false
      this.label = []
    },
    onSubmit() {
      this.$emit('closeMask', this.tagList[this.selectIndex].id)
      this.$emit('update:label', this.label)
    },
    clickNode() {
      setTimeout(() => {
        this.$formula(this.$refs['katex'])
      }, 100)
    },
  },
}
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  margin-left: 60vw;
  background: #fff;
  height: 100vh;
  float: right;
  color: #333;
  padding: 30px;
  .contentTitle {
    font-size: 21px;
    color: #000;
    font-weight: bold;
    height: 50px;
  }
  .tagView {
    div {
      float: left;
      background: #f0f0f0;
      padding: 0 15px;
      line-height: 30px;
      margin: 0 20px 15px 0;
      border-radius: 8px;
      border: 1px solid #f0f0f0;
      font-family: auto;
    }
    .chooseTag {
      color: #0148ff;
      background: rgba(0, 74, 255, 0.1);
      border: 1px solid #0148ff;
    }
  }
  .classifyTitle {
    clear: both;
    padding: 0px 0 0;
    font-size: 21px;
    span {
      font-weight: bold;
      color: #000;
      display: block;
      float: left;
      margin-right: 20px;
      line-height: 50px;
    }
    ::v-deep .v-icon.v-icon {
      font-size: 50px;
      color: #1890ff;
    }
  }
  ::v-deep .v-icon.v-icon::after {
    opacity: 0;
  }
  .treeView {
    background: #f2f6fc;
    width: 100%;
    height: calc(100vh - 350px);
    overflow: auto;
    font-size: 19px;
    font-family: auto;
    padding-top: 10px;
    ::v-deep .v-icon.v-icon.v-icon--link {
      color: #d0d0d0;
      font-size: 33px;
      width: 33px;
    }
    ::v-deep .accent--text {
      color: #0148ff !important;
    }
    ::v-deep .v-treeview-node__checkbox {
      font-size: 24px !important;
      width: 24px !important;
    }
    ::v-deep .theme--dark.v-icon:focus::after {
      opacity: 0 !important;
    }
  }
  .btnView {
    position: fixed;
    width: calc(35vw - 60px);
    bottom: 30px;
    div {
      width: calc(50% - 45px);
      float: left;
      margin-left: 30px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid #0148ff;
      height: 40px;
      line-height: 38px;
      font-size: 17px;
    }
    .resetBtn {
      color: #0148ff;
    }
    .submitBtn {
      background: #0148ff;
      color: #fff;
    }
  }
}
::v-deep .theme--dark.v-treeview {
  color: #333;
}
::v-deep .v-treeview-node__label {
  white-space: pre-wrap;
}
</style>