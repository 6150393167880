<!--
 * @Author: wyq
 * @Date: 2021-10-03 14:36:46
 * @LastEditTime: 2021-10-12 16:32:05
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\wrongQuestion\questionItem.vue
-->
<template>
  <div ref="katex" class="question-item" @click="goDetail">
    <div class="question-title" v-show="show" :class="{'revised':type == 'revised'}">{{info.title}}</div>
    <div v-for="item in info.titleImages" :key="item.url" class="image">
      <img :src="item.url" />
      <p>{{item.label}}</p>
    </div>
    <div class="footer">
      <ul class="cause-list">
        <li v-for="item in info.errorReason" :key="item.value">{{item.label}}</li>
      </ul>
      <div class="time">{{info.createAt|datefmt('YYYY-MM-DD HH:mm:ss')}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'questionItem',
  props: {
    info: Object,
    type: String,
  },
  data() {
    return {
      show: false,
    }
  },
  watch: {
    info: {
      handler() {
        setTimeout(() => {
          this.$formula(this.$refs['katex'])
          this.show = true
        }, 1)
      },
      immediate: true,
    },
  },
  methods: {
    goDetail() {
      this.$emit('clickQuestion', this.info)
    },
  },
}
</script>
<style lang="scss" scoped>
.question-item {
  padding: 1.3vw 0;
  margin: 0 28px 0.7vw;
  font-size: 16px;
  border-bottom: 1px solid #f0f0f0;
  .question-title {
    color: #333333;
    line-height: 37px;
    white-space: pre-wrap;
    &::before {
      display: inline-block;
      content: '';
      width: 6px;
      height: 18px;
      margin-right: 16px;
      background: #0148ff;
      border-radius: 6px;
      vertical-align: text-top;
    }
  }
  .revised {
    &::before {
      background: #ffb13f;
    }
  }
  .image {
    display: inline-block;
    width: 7vw;
    margin: 10px 0;
    img {
      width: 100%;
      height: auto;
    }
    p {
      margin: 0;
      text-align: center;
      font-size: 14px;
    }
    & + .image {
      margin-left: 20px;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    margin-top: 1vw;
    .cause-list {
      flex: 1;
      padding: 0;
      li {
        display: inline-block;
        height: 2.4vw;
        padding: 0 1.6vw;
        line-height: 2.4vw;
        border-radius: 1.2vw;
        font-size: 12px;
        background-color: #f0f0f0;
        color: #666;
        & + li {
          margin-left: 1.9vw;
        }
      }
    }
    .time {
      color: #999999;
    }
  }
}
</style>